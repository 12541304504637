<template>
  <div class="signature-print-main-content">
    <div class="main-title">报告预览打印</div>
    <el-card class="main-card">
      <el-table
        v-loading="loading"
        :data="report_list"
        border
        style="width: 100%"
        height="calc(100vh - 165px)"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column min-width="300" label="报告名称">
          <template slot-scope="scope">
            <div>{{ scope.row.reportName }}</div>
          </template>
        </el-table-column>
        <el-table-column width="220" label="创建日期">
          <template slot-scope="scope">
            <el-button
              v-if="!scope.row.pdfFileName"
              type="text"
              @click="create(scope.row)"
              size="small"
            >
              生成
            </el-button>
            <div v-else>{{ scope.row.recordDate }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="signDate" width="220" label="签发日期" />
        <el-table-column label="签发人" width="160" prop="recordName" />
        <el-table-column fixed="right" width="160" label="打印报告">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.pdfFileName && scope.row.pdfFileName.includes('pdf')"
              @click="handlePrint(scope.row)"
              type="text"
              size="small"
            >
              报告预览
            </el-button>
            <el-button v-else @click="handleDownload(scope.row, 'list')" type="text" size="small">
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
      title="报告详情"
      :visible.sync="dialogVisible"
      width="70%"
      :modal="false"
      style="margin-top: -5vh"
    >
      <div class="pdf-main">
        <div style="height: 32px">
          <el-button @click="handleDownload(report_address)" type="primary" size="small">
            下载
          </el-button>
        </div>
        <div class="pdf-content">
          <iframe :src="this.report_address" style="height: 890px; width: 100%"></iframe>
          <!-- <vue-office-pdf :src="report_address" /> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { PDF_HOST } from '../../../utils/globalVariable'

// import VueOfficeDocx from '@vue-office/docx'
// import VueOfficeExcel from '@vue-office/excel'
// import VueOfficePdf from '@vue-office/pdf'
//引入相关样式
// import '@vue-office/docx/lib/index.css'
// import '@vue-office/excel/lib/index.css'

export default {
  name: 'SignaturePrint',
  components: {
    // VueOfficeDocx,
    // VueOfficeExcel,
    // VueOfficePdf
  },
  data() {
    return {
      //报告数据列表
      report_list: [],
      //报告打印窗口
      dialogVisible: false,
      //报告地址
      report_address: '',
      downloadName: '',
      loading: false
    }
  },
  mounted() {
    this.get_reportlist()
  },
  created() {},

  watch: {},

  methods: {
    // 获得已有的报告模板
    get_reportlist() {
      this.report_list = []
      this.loading = true
      this.axios
        .get(`/v1/webconsole/report/list/${this.$route.query.operationId}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.report_list = res.data.data?.map((item) => {
              if (item.signed !== 'Y') {
                item.signDate = ''
                item.isSigned = false
              } else {
                item.isSigned = true
              }
              return item
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 生成
    create(val) {
      this.loading = true
      this.$api
        .post(`/v1/webconsole/report/save/doctor/${val.operationId}/${val.moduleCode}`)
        .then(() => {
          this.$message.success('生成报告成功！')
          this.get_reportlist()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 下载
    handleDownload(val, type) {
      if (type === 'list') {
        var s = encodeURI(val.pdfFileName)
        if (val.pdfFileName.includes('http')) {
          window.open(s, '_blank')
        } else {
          window.open(PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`, '_blank')
        }
      } else {
        this.axios
          .get(val, {
            responseType: 'blob'
          })
          .then((res) => {
            if (res.data && res.data.size !== 0) {
              let blob = new Blob([res.data], {
                //type类型后端返回来的数据中会有，根据自己实际进行修改
                type: 'application/pdf'
              })
              let filename = this.downloadName + '.pdf'
              if (typeof window.navigator.msSaveBlob !== 'undefined') {
                window.navigator.msSaveBlob(blob, filename)
              } else {
                var blobURL = window.URL.createObjectURL(blob)
                // 创建隐藏<a>标签进行下载
                var tempLink = document.createElement('a')
                tempLink.style.display = 'none'
                tempLink.href = blobURL
                tempLink.setAttribute('download', filename)
                if (typeof tempLink.download === 'undefined') {
                  tempLink.setAttribute('target', '_blank')
                }
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
                window.URL.revokeObjectURL(blobURL)
              }
            }
          })
      }
    },
    handlePrint(val) {
      this.downloadName = ''
      const loading = this.$loading({
        lock: true,
        text: '报告加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      setTimeout(() => {
        loading.close()
        if (!val.pdfFileName) {
          return this.$message.info('暂无报告')
        }
        this.downloadName = val.reportName
        var s = encodeURI(val.pdfFileName)
        if (val.pdfFileName.includes('http')) {
          this.report_address = s
        } else {
          this.report_address = PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
        }
        this.dialogVisible = true
      }, 1500)
    }
  }
}
</script>

<style lang="scss" scoped>
.signature-print-main-content {
  background: #eff0f7;
  .main-card {
    margin-top: 10px;
    margin-left: 10px;
    /deep/.el-card__body {
      padding: 0;
      display: flex;
      height: calc(100vh - 8rem);
    }
  }
  .main-title {
    padding: 10px 5px;
    font-size: 18px;
    font-weight: bold;
    background: #f2f2f2;
    border-bottom: 1px solid #dedede;
  }
  .el-table {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
}
.pdf-main {
  height: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .pdf-content {
    flex: 1;
    overflow: auto;
  }
}
</style>
